import React, { useEffect, useState, useRef } from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa';
import { db } from '../../../Firebase';
import { collection, getDocs, query, orderBy } from "firebase/firestore";
import './Team.sass';

function Team() {
  const [events, setEvents] = useState([]);
  const sliderRef = useRef(null);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const eventCollection = collection(db, "Team"); 
      const q = query(eventCollection, orderBy("Session", "asc")); 
      const querySnapshot = await getDocs(q);
      const teamData = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      setEvents(teamData);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  

  const settings = {
    infinite: true,
    dots: true,
    slidesToShow: 5,
    slidesToScroll: 5,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      }
    ]
  };

  const handlePrevious = () => {
    sliderRef.current.slickPrev();
  };

  const handleNext = () => {
    sliderRef.current.slickNext();
  };

  const squareImageStyle = {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  };

  return (
    <div className="tag" id="tag">
      <h1 style={{color:'black'}}>Session {a.batch}</h1>
      <div className="imgslider">
        <Slider {...settings} ref={sliderRef}>
          {events.map((event) => (
            <div className="slide" key={event.id}>
              <img  style={{height:'250px', objectFit: 'cover' }} src={event.imageUrl} alt={event.name} />
              <h3 style={{ color: 'black' }}>{event.name}</h3>
              <p style={{ color: 'black' }}>{event.position.label}</p>
              <p style={{ color: 'black' }}>Batch: {event.Session.label}</p>
            </div>
          ))}
        </Slider>
        <div className="carousel-buttons">
          <button className="carousel-button" onClick={handlePrevious}>
            <FaArrowLeft />
          </button>
          <button className="carousel-button" onClick={handleNext}>
            <FaArrowRight />
          </button>
        </div>
      </div>
    </div>
  );
}

export default Team;

const a = {
  batch: "2023-24",
  member: [
    // Your member data here
  ]
};
