import React from "react";

import FlipClockCountdown from "@leenguyen/react-flip-clock-countdown";
import "@leenguyen/react-flip-clock-countdown/dist/index.css";

import "./styles.css";

// import video
import videoBg from "./assets/video.mp4";

const Workshop = () => {
  return (
    <section className="page">
      {/* overlay */}
      <div className="overlay"></div>
      <video src={videoBg} autoPlay loop muted />
      <div className="page__content">
        <h2>Something Big update is coming
           by our team. Wait! We are launching 
           soon with amazing updates!</h2>
        <FlipClockCountdown
          className="flip-clock"
          to={new Date().getTime() + 700 * 3600 * 1000 + 5000}
        />
      </div>
    </section>
  );
};

export default Workshop;
