import React from 'react'
import { useEffect,useState } from 'react'
import { Link } from 'react-router-dom'


function Data(param) {
  const [collapse, set ]= useState(false)
  console.log(param)
  }

export default Data