import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useState, useEffect } from 'react'
// import { IoIosArrowBack } from 'react-icons/io';
// import { IoIosArrowForward } from 'react-icons/io';
import './Carousel.sass'




import { db, storage } from '../../../Firebase'
import { useRef } from 'react';
import { query, orderBy } from 'firebase/firestore';
// import { FaArrowLeft, FaArrowRight } from 'react-icons/fa';








import {
  ref,
  uploadBytesResumable,
  getDownloadURL,
} from "firebase/storage";
import {
  doc,
  addDoc,
  collection,
  updateDoc,
  getDocs,
  deleteDoc,
} from "firebase/firestore";


function Carousel() {


  const [slide, setSlide] = useState([{}]);
  const sliderRef = useRef(null);






  const Fetch = async () => {
    await getDocs(query(collection(db, "community"),))
      .then((querySnapshot) => {
        const newData = querySnapshot.docs
          .map((doc) => ({ ...doc.data(), id: doc.id }));
        setSlide(newData);
        console.log(slide);
      })
  }

  useEffect(() => {
    Fetch();
  }, []);





  const settings = {
    infinite: true,
    dots: true,
    slidesToShow: 5,
    slidesToScroll: 5,
    // nextArrow: <IoIosArrowForward />,
    // prevArrow: <IoIosArrowBack />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true
        }
      },

      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      }
    ]
  };
  const handlePrevious = () => {
    sliderRef.current.slickPrev();
  };

  const handleNext = () => {
    sliderRef.current.slickNext();
  };




  const [events, setEvents] = useState([]);
  const [file, setFile] = useState("");
  const [formData, setFormData] = useState({

    position: null,
    name: "",
    Session: null,
  });

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const eventCollection = collection(db, "community"); // Specify the correct path to your "Team" collection
      const q = query(eventCollection, ) // Create a query with the orderBy clause
      const querySnapshot = await getDocs(q);

      const teamData = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      setEvents(teamData);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
    return (

        <div className="tagC">
            <div className="imgsliderC">
                <Slider {...settings}>
                    {events.map((event) => (
                        <div className="slideC" id="card" >
                            <img src={event.imageUrl} />
                        </div>
                    ))}
                </Slider>
            </div>
        </div>
    )
}

export default Carousel